import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { defaultPageSize } from './model/searchModel';
import { ISearchRequestPagingDTOData } from '../../api';

export const QUERY_SEARCH_PARAM_CATALOG_HWGID = 'cHwgId';
export const QUERY_SEARCH_PARAM_CATALOG_HAGRID = 'cHagrId';
export const QUERY_SEARCH_PARAM_CATALOG_AGRID = 'cAgrId';
export const QUERY_SEARCH_PARAM_PAGE = 'page';
export const QUERY_SEARCH_PARAM_PAGESIZE = 'pagesize';
export const QUERY_SEARCH_PARAM_SEARCHTERM = 'searchTerm';
export const QUERY_SEARCH_PARAM_BRANDS = 'brands';
export const QUERY_SEARCH_PARAM_ROHSTOFFHERKUNFT = 'rh';
export const QUERY_SEARCH_PARAM_HERGESTELLT = 'h';
export const QUERY_SEARCH_PARAM_SALESORDERSET = 'sos';
export const QUERY_SEARCH_PARAM_TK = 'tk';
export const QUERY_SEARCH_PARAM_ECOSCORE = 'es';
export const QUERY_SEARCH_PARAM_LABELS = 'ls';
export const QUERY_SEARCH_PARAM_SUPPLIERGROUPNAMES = 'sgn';
export const QUERY_SEARCH_PARAM_SPECIALDIET = 'sd';
export const QUERY_SEARCH_PARAM_SPECIAL_ASSORTMENT = 'sa';
export const QUERY_SEARCH_PARAM_ACTION = 'a';
export const QUERY_SEARCH_PARAM_ALLERGEN = 'alg';
export const QUERY_SEARCH_PARAM_NOVELTIES = 'n';
export const QUERY_SEARCH_PARAM_HWGID = 'hwg';

export const mapSearchParamsToSearchRequest = (searchParams: URLSearchParams = new URLSearchParams(window.location.search)): ISearchRequestPagingDTOData => {
  const catalogHwgId = searchParams.has(QUERY_SEARCH_PARAM_CATALOG_HWGID) ? searchParams.get(QUERY_SEARCH_PARAM_CATALOG_HWGID) : undefined;
  const catalogHagrId = searchParams.has(QUERY_SEARCH_PARAM_CATALOG_HAGRID) ? searchParams.get(QUERY_SEARCH_PARAM_CATALOG_HAGRID) : undefined;
  const catalogAgrId = searchParams.has(QUERY_SEARCH_PARAM_CATALOG_AGRID) ? searchParams.get(QUERY_SEARCH_PARAM_CATALOG_AGRID) : undefined;
  const hwgIds = searchParams.has(QUERY_SEARCH_PARAM_HWGID) ? searchParams.get(QUERY_SEARCH_PARAM_HWGID) : undefined;

  const hwgFilterIds =
    hwgIds && hwgIds.length > 0
      ? searchParams
          .get(QUERY_SEARCH_PARAM_HWGID)!
          .split(',')
          .map((a) => parseInt(a, 10))
      : [];

  if (catalogHwgId) {
    const value = parseInt(catalogHwgId);

    if (!hwgFilterIds.includes(value)) {
      hwgFilterIds?.unshift(value);
    }
  }

  return {
    searchTerm: searchParams.has(QUERY_SEARCH_PARAM_SEARCHTERM) ? searchParams.get(QUERY_SEARCH_PARAM_SEARCHTERM)! : '',
    hwgIds: hwgFilterIds,
    hagrIds: catalogHagrId ? [parseInt(catalogHagrId)] : undefined,
    agrIds: catalogAgrId ? [parseInt(catalogAgrId)] : undefined,
    page: searchParams.has(QUERY_SEARCH_PARAM_PAGE) ? parseInt(searchParams.get(QUERY_SEARCH_PARAM_PAGE)!) : 0,
    pageSize: searchParams.has(QUERY_SEARCH_PARAM_PAGESIZE) ? parseInt(searchParams.get(QUERY_SEARCH_PARAM_PAGESIZE)!) : defaultPageSize,
    brands:
      searchParams.has(QUERY_SEARCH_PARAM_BRANDS) && searchParams.get(QUERY_SEARCH_PARAM_BRANDS)!.length > 0
        ? searchParams
            .get(QUERY_SEARCH_PARAM_BRANDS)!
            .split(',')
            .map((a) => parseInt(a, 10))
        : [],
    rohstoffHerkunft:
      searchParams.has(QUERY_SEARCH_PARAM_ROHSTOFFHERKUNFT) && searchParams.get(QUERY_SEARCH_PARAM_ROHSTOFFHERKUNFT)!.length > 0
        ? searchParams
            .get(QUERY_SEARCH_PARAM_ROHSTOFFHERKUNFT)!
            .split(',')
            .map((a) => parseInt(a, 10))
        : [],
    specialDiet:
      searchParams.has(QUERY_SEARCH_PARAM_SPECIALDIET) && searchParams.get(QUERY_SEARCH_PARAM_SPECIALDIET)!.length > 0
        ? searchParams
            .get(QUERY_SEARCH_PARAM_SPECIALDIET)!
            .split(',')
            .map((a) => parseInt(a, 10))
        : [],
    hergestellt:
      searchParams.has(QUERY_SEARCH_PARAM_HERGESTELLT) && searchParams.get(QUERY_SEARCH_PARAM_HERGESTELLT)!.length > 0
        ? searchParams
            .get(QUERY_SEARCH_PARAM_HERGESTELLT)!
            .split(',')
            .map((a) => parseInt(a, 10))
        : [],
    searchInSalesOrderSet: searchParams.has(QUERY_SEARCH_PARAM_SALESORDERSET) ? searchParams.get(QUERY_SEARCH_PARAM_SALESORDERSET) === 'true' : undefined,
    tk: searchParams.has(QUERY_SEARCH_PARAM_TK) ? searchParams.get(QUERY_SEARCH_PARAM_TK) === 'true' : undefined,
    ecoScore:
      searchParams.has(QUERY_SEARCH_PARAM_ECOSCORE) && searchParams.get(QUERY_SEARCH_PARAM_ECOSCORE)!.length > 0
        ? searchParams
            .get(QUERY_SEARCH_PARAM_ECOSCORE)!
            .split(',')
            .map((a) => parseInt(a, 10))
        : [],
    allergene:
      searchParams.has(QUERY_SEARCH_PARAM_ALLERGEN) && searchParams.get(QUERY_SEARCH_PARAM_ALLERGEN)!.length > 0
        ? searchParams
            .get(QUERY_SEARCH_PARAM_ALLERGEN)!
            .split(',')
            .map((a) => parseInt(a, 10))
        : [],
    labels:
      searchParams.has(QUERY_SEARCH_PARAM_LABELS) && searchParams.get(QUERY_SEARCH_PARAM_LABELS)!.length > 0
        ? searchParams
            .get(QUERY_SEARCH_PARAM_LABELS)!
            .split(',')
            .map((a) => parseInt(a, 10))
        : [],
    supplierGroups:
      searchParams.has(QUERY_SEARCH_PARAM_SUPPLIERGROUPNAMES) && searchParams.get(QUERY_SEARCH_PARAM_SUPPLIERGROUPNAMES)!.length > 0
        ? searchParams
            .get(QUERY_SEARCH_PARAM_SUPPLIERGROUPNAMES)!
            .split(',')
            .map((a) => parseInt(a, 10))
        : [],
    specialAssortments:
      searchParams.has(QUERY_SEARCH_PARAM_SPECIAL_ASSORTMENT) && searchParams.get(QUERY_SEARCH_PARAM_SPECIAL_ASSORTMENT)!.length > 0
        ? searchParams
            .get(QUERY_SEARCH_PARAM_SPECIAL_ASSORTMENT)!
            .split(',')
            .map((sa) => parseInt(sa, 10))
        : [],
    action: searchParams.has(QUERY_SEARCH_PARAM_ACTION) ? searchParams.get(QUERY_SEARCH_PARAM_ACTION) === 'true' : undefined,
    novelties: searchParams.has(QUERY_SEARCH_PARAM_NOVELTIES) ? searchParams.get(QUERY_SEARCH_PARAM_NOVELTIES) === 'true' : undefined
  };
};

export function useSearchQueryParams() {
  const [searchParams, setSearchParams] = useSearchParams();
  const urlSearchRequest = useMemo(() => mapSearchParamsToSearchRequest(searchParams), [searchParams]);

  const setSearchQueryParam = useCallback(
    (searchRequest: ISearchRequestPagingDTOData) => {
      setSearchParams((prev) => {
        prev.set(QUERY_SEARCH_PARAM_PAGE, searchRequest?.page ? searchRequest.page.toString() : '0');
        searchRequest.searchInSalesOrderSet !== undefined ? prev.set(QUERY_SEARCH_PARAM_SALESORDERSET, searchRequest.searchInSalesOrderSet.toString()) : prev.delete(QUERY_SEARCH_PARAM_SALESORDERSET);
        searchRequest.hwgIds && searchRequest.hwgIds.length > 0 ? prev.set(QUERY_SEARCH_PARAM_HWGID, searchRequest.hwgIds.join(',')) : prev.delete(QUERY_SEARCH_PARAM_HWGID);
        searchRequest.hwgIds && !searchRequest.searchTerm && searchRequest.hwgIds.length > 0 ? prev.set(QUERY_SEARCH_PARAM_CATALOG_HWGID, searchRequest.hwgIds[0].toString()) : prev.delete(QUERY_SEARCH_PARAM_CATALOG_HWGID);
        searchRequest.hagrIds ? prev.set(QUERY_SEARCH_PARAM_CATALOG_HAGRID, searchRequest.hagrIds[0].toString()) : prev.delete(QUERY_SEARCH_PARAM_CATALOG_HAGRID);
        searchRequest.agrIds ? prev.set(QUERY_SEARCH_PARAM_CATALOG_AGRID, searchRequest.agrIds[0].toString()) : prev.delete(QUERY_SEARCH_PARAM_CATALOG_AGRID);
        searchRequest.searchTerm ? prev.set(QUERY_SEARCH_PARAM_SEARCHTERM, searchRequest.searchTerm) : prev.delete(QUERY_SEARCH_PARAM_SEARCHTERM);
        searchRequest.brands && searchRequest.brands.length > 0 ? prev.set(QUERY_SEARCH_PARAM_BRANDS, searchRequest.brands.join(',')) : prev.delete(QUERY_SEARCH_PARAM_BRANDS);
        searchRequest.rohstoffHerkunft && searchRequest.rohstoffHerkunft.length > 0 ? prev.set(QUERY_SEARCH_PARAM_ROHSTOFFHERKUNFT, searchRequest.rohstoffHerkunft.join(',')) : prev.delete(QUERY_SEARCH_PARAM_ROHSTOFFHERKUNFT);
        searchRequest.hergestellt && searchRequest.hergestellt.length > 0 ? prev.set(QUERY_SEARCH_PARAM_HERGESTELLT, searchRequest.hergestellt.join(',')) : prev.delete(QUERY_SEARCH_PARAM_HERGESTELLT);
        searchRequest.specialDiet && searchRequest.specialDiet.length > 0 ? prev.set(QUERY_SEARCH_PARAM_SPECIALDIET, searchRequest.specialDiet.join(',')) : prev.delete(QUERY_SEARCH_PARAM_SPECIALDIET);
        searchRequest.tk !== undefined ? prev.set(QUERY_SEARCH_PARAM_TK, searchRequest.tk.toString()) : prev.delete(QUERY_SEARCH_PARAM_TK);
        searchRequest.ecoScore && searchRequest.ecoScore.length > 0 ? prev.set(QUERY_SEARCH_PARAM_ECOSCORE, searchRequest.ecoScore.join(',')) : prev.delete(QUERY_SEARCH_PARAM_ECOSCORE);
        searchRequest.labels && searchRequest.labels.length > 0 ? prev.set(QUERY_SEARCH_PARAM_LABELS, searchRequest.labels.join(',')) : prev.delete(QUERY_SEARCH_PARAM_LABELS);
        searchRequest.supplierGroups && searchRequest.supplierGroups.length > 0 ? prev.set(QUERY_SEARCH_PARAM_SUPPLIERGROUPNAMES, searchRequest.supplierGroups.join(',')) : prev.delete(QUERY_SEARCH_PARAM_SUPPLIERGROUPNAMES);
        searchRequest.specialAssortments && searchRequest.specialAssortments.length > 0 ? prev.set(QUERY_SEARCH_PARAM_SPECIAL_ASSORTMENT, searchRequest.specialAssortments.join(',')) : prev.delete(QUERY_SEARCH_PARAM_SPECIAL_ASSORTMENT);
        searchRequest.action !== undefined ? prev.set(QUERY_SEARCH_PARAM_ACTION, searchRequest.action.toString()) : prev.delete(QUERY_SEARCH_PARAM_ACTION);
        searchRequest.allergene && searchRequest.allergene.length > 0 ? prev.set(QUERY_SEARCH_PARAM_ALLERGEN, searchRequest.allergene.join(',')) : prev.delete(QUERY_SEARCH_PARAM_ALLERGEN);
        searchRequest.novelties !== undefined ? prev.set(QUERY_SEARCH_PARAM_NOVELTIES, searchRequest.novelties.toString()) : prev.delete(QUERY_SEARCH_PARAM_NOVELTIES);

        // Remove emtpy values from URLSearchParams -> otherwise the URL will be polluted with empty values
        for (const [key, value] of prev.entries()) {
          if (value === '') {
            prev.delete(key);
          }
        }

        return prev;
      });
    },
    [setSearchParams]
  );

  return { urlSearchRequest, setSearchQueryParam };
}
