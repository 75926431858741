import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Grid } from '@mui/material';
import { Article } from '../../../shared/articles/models/article';
import { ppConstants } from '../../../../util/ppConstants';

interface IArticleDetailAmountPerpalletProps {
  article: Article;
}

const ArticleDetailAmountPerPallet = ({ article }: IArticleDetailAmountPerpalletProps) => {
  const { t } = useTranslation();

  if (article.quantityPallet === 0
    || article.sellAmount === 0
    || article.quantityPallet === article.sellAmount
    || article.priceCode === ppConstants.PRICECODE_WEIGHTCONTROLLED) {
    return <></>;
  }

  const amountPerPallet = computeAmountPerPallet(article);

  return (
    <>
      <Grid container alignItems={'center'} sx={{ minHeight: '60', alignItems: 'center', margin: 0 }} direction={'row'} spacing={1}>
        <Grid item sx={{ width: '200px', minWidth: '200px' }}>
          <Typography variant={'subtitle2'}> {t('Menge pro Palette')}</Typography>
        </Grid>
        <Grid item>
          <Typography variant={'body2'}>{amountPerPallet}</Typography>
        </Grid>
      </Grid>
    </>
  );
};

function computeAmountPerPallet(artcile: Article) {
  return (artcile.quantityPallet ? artcile.quantityPallet : 0) / artcile.sellAmount + ' ' + artcile.sellUnit
    + ' x ' + artcile.sellAmount + ' ' + artcile.articleUnit;
}

export default ArticleDetailAmountPerPallet;
